'use es6';

window.hubspot.require(['enviro', 'quickFetch'], (enviro, quickFetch) => {
  /*
    IMPORTANT:
    if you update this method you must mirror it in quickFetcher-test.js
  */
  const url = new URL(window.location.href);
  const isTest = url.searchParams.get('isPreview') || url.searchParams.get('isDebug') || url.searchParams.get('test');
  const noParams = !isTest && url.search.length === 0;
  if (isTest || noParams) {
    return;
  }
  const surveyType = url.pathname.slice(1);
  const hublet = enviro.getHublet();
  const getApiUrl = () => {
    const hsUrl = enviro.isQa() ? 'hubspotqa' : 'hubspot';
    if (hublet !== 'na1') {
      return `https://app-${hublet}.${hsUrl}.com/api-passthrough`;
    }
    return `https://app.${hsUrl}.com/api-passthrough`;
  };
  url.searchParams.append('surveyType', surveyType.toUpperCase());
  url.searchParams.append('emailSentAt', url.searchParams.get('ts'));
  if (surveyType === 'ces' && !isTest) {
    url.searchParams.append('objectReference.objectReferenceId', url.searchParams.get('ticketId'));
    url.searchParams.append('objectReference.objectReferenceType', 'TICKET');
  }
  const query = url.searchParams.toString();
  // console.log(query); // for debugging stuff
  quickFetch.makeEarlyRequest('email-verify', {
    url: `${getApiUrl()}/feedback/public/v1/email-verify?${query}`,
    type: 'GET',
    dataType: 'json',
    contentType: 'application/json',
    timeout: 14000
  });
});